<template>
    <BaseCard
        :entry="entry"
        :is-inline="isInline"
        class="article-card"
    >
        <template #meta>
            <EntryMetadata
                :entry="entry"
                :should-link-dossiers="false"
                :show-dossier-dropdown="false"
            />
        </template>
    </BaseCard>
</template>

<script setup>
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import EntryMetadata from '@/components/EntryMetadata/EntryMetadata.vue';

defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    isInline: {
        type: Boolean,
        required: false,
        default: false
    }
});

</script>

<style lang="less" src="./ArticleCard.less" />
